<script>
import Layout from '@layouts/list-overview/list-overview'
import { pick } from 'lodash'
import i18n from '@src/i18n'
import RefundCard from '@components/refund-card/refund-card'
import CustomLoader from '@components/custom-loader/custom-loader'
import Subheader from '@components/page-sub-header/page-sub-header'
import { enterpriseComputed } from '@state/helpers/enterprise'
import formatDate from '@utils/format-date'

import {
  reclaimsOverviewMethods,
  reclaimsOverviewComputed,
} from '@state/helpers/reclaimsOverview'

export default {
  page: {
    title: i18n.t('INVOICE'),
    meta: [{ name: 'description', content: '' }],
  },
  components: { Layout, CustomLoader, RefundCard, Subheader },
  data() {
    return {
      invoiceId: this.$route.params.id,
    }
  },
  computed: {
    ...pick(reclaimsOverviewComputed, [
      'currentInvoice',
      'refunds',
      'getRefundsInProgress',
    ]),
    ...enterpriseComputed,
    totalReclaimedAmount() {
      if (this.refunds) {
        return this.refunds.reduce((acc, refund) => {
          return acc + refund.amount
        }, 0)
      }
      return '-'
    },
  },
  watch: {
    currentEnterpriseId(newValue, oldValue) {
      if (oldValue !== newValue && newValue) {
        this.getRefunds()
        // If there is no currentInvoice, that means page was refreshed manually.
        if (!this.currentInvoice) this.getInvoiceById(this.invoiceId)
      }
    },
  },
  mounted() {
    if (this.currentEnterpriseId) {
      this.getRefunds(this.invoiceId)
      if (!this.currentInvoice) this.getInvoiceById(this.invoiceId)
    }
  },
  methods: {
    ...pick(reclaimsOverviewMethods, ['getInvoiceById', 'getRefunds']),
    setCurrentPage(page) {
      return this.setPage(page).then(() => this.getReclaims())
    },
    formatDate(date) {
      return formatDate(date)
    },
  },
}
</script>

<template>
  <Layout>
    <Subheader
      :title="`${$t('ACCOUNT_STATEMENT')} ${
        currentInvoice ? formatDate(currentInvoice.date) : ''
      }`"
    />

    <!-- TABS -->
    <div :class="$style.listSelector">
      <ul :class="$style.theTabs">
        <li :class="[$style.allCountries, $style.active]"
          ><div :class="$style.verticalOffset">
            {{ $t('ALL_COUNTRIES') }}
          </div>
        </li>
      </ul>

      <!-- TODO:  This may be needed if decision is made to
      show total invoice amount-->

      <!-- Total reclaimed value -->
      <!-- <div :class="$style.tabItem"
        ><span :class="$style.tabTitle">{{
          $t('TOTAL_RECLAIMED_AMOUNT')
        }}</span>
        {{ 0 }} {{ 'EUR' }}</div
      > -->
    </div>

    <div :class="$style.tabsOffset">
      <CustomLoader v-if="getRefundsInProgress" />
      <div v-if="refunds && refunds.length">
        <RefundCard
          v-for="(refund, index) in refunds"
          :key="refund.id"
          :index="index"
          :date="refund.date"
          :status="refund.status"
          :amount="refund.amount"
          :currency="refund.currency"
          :reclaim-id="refund.reclaim.id"
          :reclaim-amount="refund.reclaim.amount"
          :reclaim-currency="refund.reclaim.currency"
          :reclaim-period="refund.reclaim.period"
          :reclaim-country="refund.reclaim.country"
          :reclaim-year="refund.reclaim.year"
          :related-refunds="refund.relatedRefunds"
        />
      </div>
      <div v-else :class="$style.error">
        {{ $t('RECLAIMS.NO_REFUNDS_TO_SHOW') }}
      </div>
    </div>
  </Layout>
</template>
<style lang="scss" module>
@import '@design';

.listSelector {
  position: relative;
  top: -55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  background: rgba(lighten($dark, 4%), 0.9);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.verticalOffset {
  position: relative;
  top: 4px;
}

.tabsOffset {
  position: relative;
  top: -55px;
}

.reclaims {
  margin-top: 10px;
}
.error {
  margin-top: 35px;
}
.hide {
  display: none;
}

.tabTitle {
  margin: 0 5px;
  font-weight: initial;
  color: $grey;
}

.tabItem {
  margin: 0 20px;
  font-weight: bold;
  color: white;
}

.theTabs {
  padding: 0 !important;
  li {
    height: 55px;
    padding: 0 39px 0 55px;
    margin-top: 5px;
    margin-right: 2px;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    color: white;
    background-color: rgba(lighten($dark, 6%), 1);
    background-repeat: no-repeat;
    background-position: calc(0% + 24px) calc(50%);
    background-size: 17px 17px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: all 0.3s cubic-bezier(0.36, 0.69, 0.43, 1.16);
    transform: translate3d(0, -5px, 0);
    &:hover {
      cursor: pointer;
      background-color: rgba(lighten($dark, 9%), 1);
      transition: all 0.1s ease-in-out;
    }
    &.active {
      position: relative;
      top: 2px;
      height: 55px;
      color: $dark;
      cursor: pointer;
      background-color: white;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      transition: all 0.3s cubic-bezier(0.36, 0.69, 0.43, 1.16);
      transform: translate3d(0, -5px, 0);
    }
    &:first-child {
      border-top-left-radius: 7px !important;
    }
  }
}
.allCountries {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14c-1.5 0-2.8-.5-3.9-1.4.6-.8.7-1.8.1-2.6-.5-.7-1.3-1.4-1.3-2 0-.8 1.9-1.1 2.5-1.9.7-.8 0-2.3-.7-3.1.8-.5 1.7-.9 2.8-1 .2.5.9 1.1 1.2 1.5.5.8.6 1.3 1.3 1.3.2 0 2 0 3-.3.6 1 1 2.2 1 3.5 0 3.3-2.7 6-6 6zm2.2-7.3C9 6.7 7.9 7.5 8 8.6c.3 2.5-.5 3.4.6 3.9 1.3.5 3.9-1.9 3.8-3.9 0-1.1-.9-1.9-2.2-1.9z' fill='%230072E1'/%3E%3C/svg%3E%0A");
  &.active {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14c-1.5 0-2.8-.5-3.9-1.4.6-.8.7-1.8.1-2.6-.5-.7-1.3-1.4-1.3-2 0-.8 1.9-1.1 2.5-1.9.7-.8 0-2.3-.7-3.1.8-.5 1.7-.9 2.8-1 .2.5.9 1.1 1.2 1.5.5.8.6 1.3 1.3 1.3.2 0 2 0 3-.3.6 1 1 2.2 1 3.5 0 3.3-2.7 6-6 6zm2.2-7.3C9 6.7 7.9 7.5 8 8.6c.3 2.5-.5 3.4.6 3.9 1.3.5 3.9-1.9 3.8-3.9 0-1.1-.9-1.9-2.2-1.9z' fill='%23222F44'/%3E%3C/svg%3E%0A");
  }
}
</style>
