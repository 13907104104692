<script>
import RECLAIM_STATUSES from '@constants/reclaimStatuses'
import formatDate from '@utils/format-date'
import i18n from '@src/i18n'
import { pick } from 'lodash'
import { reclaimsOverviewComputed } from '@state/helpers/reclaimsOverview'

export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    period: {
      default: 'Q1',
      type: String,
    },
    amount: {
      default: null,
      type: Number,
    },
    status: {
      default: null,
      type: String,
    },
    currency: {
      default: null,
      type: String,
    },
    progress: {
      default: 100,
      type: Number,
    },
    reclaimId: {
      default: null,
      type: String,
    },
    reclaimAmount: {
      default: null,
      type: Number,
    },
    reclaimCurrency: {
      default: null,
      type: String,
    },
    reclaimPeriod: {
      default: null,
      type: String,
    },
    reclaimCountry: {
      default: null,
      type: String,
    },
    reclaimYear: {
      default: null,
      type: Number,
    },
    relatedRefunds: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      RECLAIM_STATUSES,
    }
  },
  computed: {
    ...pick(reclaimsOverviewComputed, ['currentInvoice']),
    relatedRefundsSum() {
      return this.relatedRefunds.reduce((acc, refund) => {
        return acc + refund.amount
      }, 0)
    },
    relatedRefundsTooltips() {
      const refundsTooltipArr = this.relatedRefunds.map((refund) => {
        return `${i18n.t('ACCOUNT_STATEMENT')} ${formatDate(
          refund.invoice.date
        )} - ${refund.amount} ${refund.currency}`
      })
      return refundsTooltipArr
    },
    // These are the names of the percentages of three sections of progress bar on refund card.
    // 1st section, value of current refund
    refundPercentage() {
      return Math.ceil((this.amount / this.reclaimAmount) * 100)
    },
    // 2nd section, value of related refunds payed out earlier
    relatedRefundsPercentage() {
      return Math.ceil((this.relatedRefundsSum / this.reclaimAmount) * 100)
    },
    // 3rd section, value of a corresponding reclaim amount
    reclaimPercentage() {
      return 100 - this.refundPercentage - this.relatedRefundsPercentage
    },
    refundStyle() {
      return { width: `${this.refundPercentage}%` }
    },
    refundsStyle() {
      return { width: `${this.relatedRefundsPercentage}%` }
    },
    reclaimStyle() {
      return { width: `${this.reclaimPercentage}%` }
    },
  },
  methods: {
    getQuarterRange(period) {
      const Q1Start = formatDate(new Date(this.reclaimYear, 0, 1))
      const Q1End = formatDate(new Date(this.reclaimYear, 2, 31))

      const Q2Start = formatDate(new Date(this.reclaimYear, 3, 1))
      const Q2End = formatDate(new Date(this.reclaimYear, 5, 30))

      const Q3Start = formatDate(new Date(this.reclaimYear, 6, 1))
      const Q3End = formatDate(new Date(this.reclaimYear, 8, 30))

      const Q4Start = formatDate(new Date(this.reclaimYear, 9, 1))
      const Q4End = formatDate(new Date(this.reclaimYear, 11, 31))

      const ranges = {
        Q1: `${Q1Start} - ${Q1End}`,
        Q2: `${Q2Start} - ${Q2End}`,
        Q3: `${Q3Start} - ${Q3End}`,
        Q4: `${Q4Start} - ${Q4End}`,
      }
      return ranges[period]
    },
    onCardClick() {
      this.$router.push(
        `/reclaim/${this.reclaimCountry}/${this.reclaimPeriod}/${this.reclaimId}`
      )
    },
    formatDate(date) {
      return date && formatDate(date)
    },
    getStatusClass(string) {
      return string.replace('_', '').toLowerCase()
    },
  },
}
</script>
<template>
  <div class="refundCard" @click="onCardClick">
    <v-layout class="cardRow">
      <v-flex class="flagRow">
        <img
          :src="require(`../../assets/flags/${reclaimCountry}.png`)"
          :alt="reclaimCountry"
          loading="lazy"
          height="34"
          width="48"
        />
        <!-- Title -->
        <h3>{{ $t(`ALPHA3_COUNTRY.${reclaimCountry}`) }}</h3>
      </v-flex>

      <!-- Period -->
      <div class="period"
        ><p>{{ $t('PERIOD') }}:</p></div
      >
      <v-flex class="range">
        <p>{{ getQuarterRange(reclaimPeriod) }}</p>
      </v-flex>

      <!-- Progress bar -->
      <v-flex class="progress">
        <div class="progressBar">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div
                :style="refundStyle"
                class="segment segment1"
                v-on="on"
              ></div>
            </template>
            <span>{{
              `${$t('REFUND_TOOLTIP_REFUND')} ${amount} ${currency}`
            }}</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div
                :style="refundsStyle"
                class="segment segment2"
                v-on="on"
              ></div>
            </template>
            <div class="refundsTooltip">
              <div>{{ `${$t('REFUND_TOOLTIP_REFUNDS')}` }}</div>
              <div
                v-for="(tooltip, idx) in relatedRefundsTooltips"
                :key="idx"
                >{{ tooltip }}</div
              >
            </div>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div
                :style="reclaimStyle"
                class="segment segment3"
                v-on="on"
              ></div>
            </template>
            <span>{{
              `${$t(
                'REFUND_TOOLTIP_RECLAIM'
              )} ${reclaimAmount} ${reclaimCurrency}`
            }}</span>
          </v-tooltip>
        </div>

        <div class="progressBottom">
          <div class="label"
            >{{ $t(RECLAIM_STATUSES['RECLAIMED'].label) }}:</div
          >
          <div class="amount">
            {{ amount }}
            {{ currency }}</div
          >
        </div>
      </v-flex>

      <!-- Status -->
      <v-flex :class="`statusTab ${getStatusClass(status)}`">
        {{ $t(RECLAIM_STATUSES[status] ? RECLAIM_STATUSES[status].label : '') }}
      </v-flex>
    </v-layout>
  </div>
</template>

<style scoped lang="scss">
@import '@design';

// This should be removed when API task is complete, note the spelling mistake of "MANUEL REVIEW"
.reviewfile,
.reviewiban,
.review,
.autoreview,
.manueleview {
  background-color: $in-review;
}

.returned {
  background-color: $update-needed;
}

.approved,
.sent {
  background-color: $sent-to-authorities;
}

.submitted {
  background-color: $submitted;
}

.reclaimed {
  background-color: $reclaimed;
}

.invoiced {
  background-color: $in-review;
}

.statusTab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  padding: 2px 10px;
  margin-left: 30px;
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  border-radius: 4px;
}

.period {
  p {
    margin: 0;
    font-size: 14px;
  }
}

.refundsTooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.range {
  p {
    margin: 0;
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
    color: $dark;
  }
}

.progressBottom {
  display: flex;
  justify-content: space-between;
}

.cardRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flagRow {
  display: flex;
  flex-direction: row;
  flex-shrink: 2;
  align-items: center;
  justify-content: flex-start;
}

.pdfBtn {
  margin: 0 10px;
}

.refundCard {
  display: flex;
  align-items: center;
  min-height: 100px;
  padding: 0 120px 0 30px;
  margin: 0 0 10px;
  background: #fff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23F6F7F8' d='M22.5 0h1C35.9264069 0 46 10.0735931 46 22.5v1C46 35.9264069 35.9264069 46 23.5 46h-1C10.0735931 46 0 35.9264069 0 23.5v-1C0 10.0735931 10.0735931 0 22.5 0z'/%3e%3cpath fill='%23222F44' fill-rule='nonzero' d='M25.5857864 23l-6.7928932-6.7928932c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136.3905243-.3905243 1.0236893-.3905243 1.4142136 0l7.5 7.5c.3905243.3905243.3905243 1.0236893 0 1.4142136l-7.5 7.5c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L25.5857864 23z'/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 30px top 50%;
  border-radius: 7px;

  // hover animation
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: 1s cubic-bezier(0.23, 1.02, 0.28, 1.12);
  transform: translate3d(0, 0, 0);

  &:hover,
  &:focus {
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23e6eaed' d='M22.5 0h1C35.9264069 0 46 10.0735931 46 22.5v1C46 35.9264069 35.9264069 46 23.5 46h-1C10.0735931 46 0 35.9264069 0 23.5v-1C0 10.0735931 10.0735931 0 22.5 0z'/%3e%3cpath fill='%23222F44' fill-rule='nonzero' d='M25.5857864 23l-6.7928932-6.7928932c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136.3905243-.3905243 1.0236893-.3905243 1.4142136 0l7.5 7.5c.3905243.3905243.3905243 1.0236893 0 1.4142136l-7.5 7.5c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L25.5857864 23z'/%3e%3c/g%3e%3c/svg%3e");
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.11);
    transition: 1s cubic-bezier(0.23, 1.02, 0.28, 1.12);
    transform: translate3d(0, -2px, 0);
  }
}

h3 {
  margin-left: 20px;
  font-size: 24px;
  line-height: 28px;
}

.progress {
  max-width: 220px;
  margin-right: 30px;
  .label,
  .amount {
    font-weight: 300;
    color: $grey;
  }
  .label {
    margin-top: 16px;
    color: $grey;
  }
  .amount {
    margin-top: 15px;
    font-weight: 700;
    color: $dark;
  }
}

.progressBar {
  display: flex;
  height: 9px;
  border-radius: 5px;
}

.segment {
  border-radius: 5px;
}

.segment1 {
  background-color: $refund-progress-a;
}
.segment2 {
  background-color: $refund-progress-b;
}
.segment3 {
  background-color: $refund-progress-c;
}
</style>
